<template>
  <div class="pre-body pre-body-sm">
    <div v-if="!connecting">
      <div class="pre-headline centered">Class has started!</div>

      <div class="pre-classtime">
        <div class="pre-classtime--block">
          <img
            class="pre-classtime--icon"
            src="/assets/images/calendar.png"
            alt=""
          />
          <div class="pre-classtime--copy">{{ startDate }}</div>
        </div>
        <div class="pre-classtime--block">
          <img
            class="pre-classtime--icon"
            src="/assets/images/time.png"
            alt=""
          />
          <div class="pre-classtime--copy">{{ startTime }}</div>
        </div>
      </div>

      <div class="pre-pad"></div>

      <div class="pre-classphoto filtered">
        <img
          class="pre-filter--background"
          src="/assets/images/filter-color.png"
          alt=""
        />
        <img
          class="pre-filter--corner pre-filter--corner-topleft"
          src="/assets/images/filter-topleft.png"
          alt=""
        />
        <img
          class="pre-filter--corner pre-filter--corner-topright"
          src="/assets/images/filter-topright.png"
          alt=""
        />
        <img
          class="pre-filter--corner pre-filter--corner-bottomleft"
          src="/assets/images/filter-bottomleft.png"
          alt=""
        />
        <img
          class="pre-filter--corner pre-filter--corner-bottomright"
          src="/assets/images/filter-bottomright.png"
          alt=""
        />
        <img
          class="pre-classphoto--img"
          src="/assets/images/ghost.png"
          alt=""
        />
      </div>

      <div class="pre-pad"></div>

      <div class="pre-team">
        <div class="pre-team--name">
          <img src="/assets/images/corner.png" alt="" />
          <div class="pre-team--copy" v-if="ticketInfo">
            {{ ticketInfo.ticketType }}
          </div>
        </div>
        <div class="pre-subhead centered" v-if="currentProfile">
          {{ currentProfile.nickName }}
        </div>
      </div>

      <div class="pre-pad"></div>

      <div class="pre-btns centered">
        <img
          class="pre-btn--lights"
          src="/assets/images/knightrider.png"
          alt=""
        />
        <pnw-button variant="primary" size="xl" @click="enterClass">
          Enter Class
        </pnw-button>
        <img
          class="pre-btn--lights flipped"
          src="/assets/images/knightrider.png"
          alt=""
        />
      </div>
    </div>
    <connecting v-if="connecting" />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import PnwButton from "../../components/PnwButton.vue";
import dayHelpers from "../../utils/dayHelper";
import Connecting from "./Connecting.vue";
export default {
  name: "Expedited",
  components: { Connecting, PnwButton },
  data() {
    return {
      connecting: false,
    };
  },
  methods: {
    enterClass() {
      const domain = process.env.VUE_APP_GHOSTBUSTER;
      window.open(
        `${domain}/#/join-from-pnw/${this.currentProfile.id}/${this.currentAuthToken}`,
        "_blank"
      );
    },
  },
  computed: {
    ...mapGetters({
      currentProfile: "currentProfile",
      currentAuthToken: "currentAuthToken",
      ticketInfo: "ticketInfo",
    }),
    startDate() {
      return dayHelpers.getShowDate(this.ticketInfo);
    },
    startTime() {
      return dayHelpers.getShowTime(this.ticketInfo);
    },
  },
};
</script>
